.container {
  align-items: center;
}

.question {
  display: flex;
  justify-content: center;
  min-width: 24px;
}

.popover {
  margin-bottom: 10px;
}
