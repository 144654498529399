.icon {
  position: absolute;
  color: var(--skinWarning);
  opacity: 0.5;
  font-size: 26px;
  transform: rotateY(90deg);
}

.icon:nth-child(1) {
  animation: topInChatSuccess 5s 0s infinite;
  top: 38%;
  left: 21%;
}

.icon:nth-child(2) {
  animation: topInChatSuccess 5s 0.75s infinite;
  top: 3%;
  left: 47%;
}

.icon:nth-child(3) {
  animation: topInChatSuccess 5s 1.5s infinite;
  top: 38%;
  right: 19%;
}

.icon:nth-child(4) {
  animation: topInChatSuccess 5s 2.25s infinite;
  top: 15%;
  left: 19%;
}

.icon:nth-child(5) {
  animation: topInChatSuccess 5s 3s infinite;
  top: 15%;
  right: 19%;
}

@keyframes topInChatSuccess {
  0%,
  22.5% {
    transform: rotateY(90deg);
  }
  30% {
    transform: rotateY(0deg);
  }
  37.5%,
  100% {
    transform: rotateY(90deg);
  }
}
