.floating {
  position: absolute;
  left: 0;
  right: 0;

  --topFloatingSpacing: 10px;
}

.container {
  composes: floating;
  z-index: 500;
  transition: var(--themeTransition);
}

.notification {
  composes: floating;
}

.topHeader {
  top: calc(var(--headerHeight) + var(--topFloatingSpacing));
}

.bottomHeader {
  top: max(var(--topWrapperHeight), var(--topFloatingSpacing));
}

/* Add extra space on loveis system notification */
.floatingSubheader {
  top: calc(var(--topWrapperHeight) + var(--topFloatingSpacing));
}

.bottomHeader.scrolled {
  top: var(--topFloatingSpacing);
}
