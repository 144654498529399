.light {
  --skinPrimaryLite: var(--skinPrimaryLiteOnLight);
  --skinAccentLite: var(--skinAccentLiteOnLight);
  --skinSuccessLite: var(--skinSuccessLiteOnLight);
  --skinDangerLite: var(--skinDangerLiteOnLight);
  --skinWarningLite: var(--skinWarningLiteOnLight);
}

.dark {
  --skinPrimaryLite: var(--skinPrimaryLiteOnDark);
  --skinAccentLite: var(--skinAccentLiteOnDark);
  --skinSuccessLite: var(--skinSuccessLiteOnDark);
  --skinDangerLite: var(--skinDangerLiteOnDark);
  --skinWarningLite: var(--skinWarningLiteOnDark);
}
