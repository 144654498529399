.wrap {
  display: flex;
}

.dash {
  margin: 10px;
  width: 20px;
  order: 2;
  display: flex;
  align-items: flex-end;
  color: var(--themeTextColorMuted);
  justify-content: center;
}

.select {
  flex: 1;
  order: 3;
}

.select:first-child {
  order: 1;
}
