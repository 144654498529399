.icon {
  position: absolute;
  top: 32%;
  left: 50%;
  z-index: -1;
  opacity: 0.5;
  color: var(--skinDanger);
}

.icon:first-child {
  animation: moreLikesSuccess1 7s ease-in-out both infinite;
  font-size: 16px;
}

.icon:nth-child(2) {
  animation: moreLikesSuccess2 7s ease-in-out both infinite;
  font-size: 16px;
}

.icon:nth-child(3) {
  animation: moreLikesSuccess3 7s ease-in-out both infinite;
  font-size: 16px;
}

.icon:nth-child(4) {
  animation: moreLikesSuccess4 7s ease-in-out both infinite;
  font-size: 24px;
}

.icon:nth-child(5) {
  animation: moreLikesSuccess5 7s ease-in-out both infinite;
  font-size: 16px;
}

.icon:nth-child(6) {
  animation: moreLikesSuccess6 7s ease-in-out both infinite;
  font-size: 30px;
}

.icon:nth-child(7) {
  animation: moreLikesSuccess7 7s ease-in-out both infinite;
  font-size: 27px;
}

.icon:nth-child(8) {
  animation: moreLikesSuccess8 7s ease-in-out both infinite;
  font-size: 20px;
}

@keyframes moreLikesSuccess1 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: 34%;
    left: -14%;
  }
  22% {
    opacity: 0;
  }
}

@keyframes moreLikesSuccess2 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: 43%;
    left: 114%;
  }
  22% {
    opacity: 0;
  }
}

@keyframes moreLikesSuccess3 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: -9%;
    left: -14%;
  }
  22% {
    opacity: 0;
  }
}

@keyframes moreLikesSuccess4 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: 3%;
    left: -20%;
  }
  22% {
    opacity: 0;
  }
}

@keyframes moreLikesSuccess5 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: -6%;
    left: 21%;
  }
  22% {
    opacity: 0;
  }
}

@keyframes moreLikesSuccess6 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: -28%;
    left: 68%;
  }
  22% {
    opacity: 0;
  }
}

@keyframes moreLikesSuccess7 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: -5%;
    left: 47%;
  }
  22% {
    opacity: 0;
  }
}

@keyframes moreLikesSuccess8 {
  from,
  to {
    top: 32%;
    left: 50%;
    opacity: 0;
  }
  1% {
    opacity: 0.5;
  }
  21% {
    top: 29%;
    left: 80%;
  }
  22% {
    opacity: 0;
  }
}
