.photo {
  width: 60px;
  height: 60px;

  /**
   * Used for online badge background
   * @see Confirmation.css
   */
  color: inherit;
}

.title {
  padding-right: 40px; /* Since there is close action */
}
