.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: inherit;
}

.counter {
  position: relative;
  width: 100%;
  padding: 20px 0;
  background: inherit;
  z-index: 1; /* is used for cases with full height photo */
  cursor: default;
  min-height: 60px;
}

.count {
  position: relative;
  color: var(--themeTextColorInverse);
  text-align: center;
}

.overContent {
  position: absolute;
  top: 0;
  background: transparent;
}

.gallery {
  position: relative;
  flex: 1;
  width: 100%;
}

.withBottom {
  padding-bottom: 60px;
}

.details {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 60px;
  display: flex;
  align-items: center;
}
