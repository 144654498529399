.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.content {
  position: relative;
  width: 100%;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.action {
  z-index: 2;
}

