.button {
  min-width: 160px;
  margin: auto;
}

.photo {
  width: 120px;
  margin: 20px auto;
  color: var(--themeCard);
}
