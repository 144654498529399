.wrap {
  padding: 0 20px;
}

.content {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentPhone {
  composes: content;
  justify-content: left;
}

.text {
  margin: 0 4px 0 20px;
}

.close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 10px;
  top: 0;
  height: 100%;
}

.floatingClose {
  composes: close;
  right: 20px;
}
