.subTitle {
  margin-bottom: 10px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.item .icon {
  margin-right: 10px;
}

.item {
  margin-top: 10px;
  width: 260px;
}

.fullWidth {
  width: 100%;
}
