.photo {
  width: 60px;
  height: 60px;
}

.header {
  margin-top: 60px;
}

.headerText {
  width: 90%;
  margin-right: 40px;
}

.title {
  display: flex;
  padding-bottom: 20px;
  width: 100%;
}

.wrap {
  width: 100%;
}

.tag {
  width: 20px;
}

.url {
  margin-right: 10px;
  width: 100%;
}

.step,
.stepFirst {
  position: relative;
  margin-right: 20px;
  width: 20px;
  height: 100%;
}

.stepFirst {
  margin-top: 10px;
}

.row {
  display: flex;
}

.row .step::before,
.row .stepFirst::before {
  content: '';
  display: block;
  width: 1px;
  height: calc(100% - 20px); /* 20p - step height */
  background: var(--themeDivider);
  position: absolute;
  top: 30px;
  left: 10px;
  min-height: 10px;
}

.rowLast {
  composes: row;
}

.rowLast .step::before,
.rowLast .stepFirst::before {
  content: none;
}

.rowLast .title {
  padding-bottom: 0;
}

.wrapBrowser {
  overflow: hidden;
}

.browser {
  display: flex;
  padding: 10px;
  background-color: var(--themeCardLight);
  border-radius: var(--themeRadius);
}

.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textAnimated {
  animation: textAnimation 1.6s infinite linear;
}

@keyframes textAnimation {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
