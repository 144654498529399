.center {
  text-align: center;
}

.left {
  text-align: left;
}

.link {
  padding: 0 4px;
}
