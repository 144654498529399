.inline {
  display: inline-flex;
}

.svg {
  height: 100%;
  width: 100%;
}

.none {
  width: 100%;
  height: 100%;
}

.smallest {
  width: 12px;
  height: 12px;
}

.small {
  width: 16px;
  height: 16px;
}

.medium {
  width: 20px;
  height: 20px;
}

.large {
  width: 32px;
  height: 32px;
}

.big {
  width: 60px;
  height: 60px;
}

.huge {
  width: 80px;
  height: 80px;
}

/**
 * Since in most of cases small and smallest icons are placed into
 * text, and text have bigger line height, we fix vertical alignment.
 * Cascade because 'textTop' is only needed for 'inline'.
 */
.textTop.inline.smallest,
.textTop.inline.small {
  vertical-align: text-top;
}
