.banner {
  text-align: center;
  background: var(--skinPrimary);
}

.percent {
  display: inline-block; /* set width by content to prevent scroll because animation scale content */
  line-height: 76px;
  animation: pulse 1.4s 3 ease-in-out;
}

.text {
  text-transform: uppercase;
}

.discount {
  text-decoration: line-through;
}

.action {
  min-width: 200px;
  margin: 0 auto;
}

.blackfriday,
.blackfridayasia {
  background: url(../images/blackFriday.png) no-repeat var(--skinPrimary) right top / cover;
}

@keyframes pulse {
  from,
  to {
    transform: perspective(1px) scale(1); /* perspective(1px) is fix for blurry text on android native browser */
  }
  50% {
    transform: perspective(1px) scale(1.2); /* perspective(1px) is fix for blurry text on android native browser */
  }
}
