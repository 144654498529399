.confetti {
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}

.item {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  animation-timing-function: linear;
  animation-delay: 0.1s;
  animation-fill-mode: both;
}

.waitPopupAnimation {
  animation-delay: 0.65s;
}

.icon {
  animation: rotate 0.7s infinite linear;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.primary {
  color: var(--skinPrimary);
  fill: currentColor;
  opacity: 0.5;
}

.gold {
  color: #f7c723;
  fill: currentColor;
  opacity: 0.8;
}

.gray {
  color: #d8dcea;
  fill: currentColor;
}

.item:nth-child(1) {
  animation-duration: 1.5s;
  animation-name: confetti1;
}

.item:nth-child(2) {
  animation-duration: 1.2s;
  animation-name: confetti2;
}

.item:nth-child(3) {
  animation-duration: 1.4s;
  animation-name: confetti3;
}

.item:nth-child(4) {
  animation-duration: 1.1s;
  animation-name: confetti4;
}

.item:nth-child(5) {
  animation-duration: 1.4s;
  animation-name: confetti5;
}

.item:nth-child(6) {
  animation-duration: 1.2s;
  animation-name: confetti6;
}

.item:nth-child(7) {
  animation-duration: 1.7s;
  animation-name: confetti7;
}

.item:nth-child(8) {
  animation-duration: 1s;
  animation-name: confetti8;
}

.item:nth-child(9) {
  animation-duration: 1.1s;
  animation-name: confetti9;
}

.item:nth-child(10) {
  animation-duration: 1.4s;
  animation-name: confetti10;
}

.item:nth-child(11) {
  animation-duration: 1.3s;
  animation-name: confetti11;
}

.item:nth-child(12) {
  animation-duration: 1.2s;
  animation-name: confetti12;
}

.item:nth-child(13) {
  animation-duration: 1.14s;
  animation-name: confetti13;
}

.item:nth-child(14) {
  animation-duration: 1.24s;
  animation-name: confetti14;
}

.item:nth-child(15) {
  animation-duration: 1.44s;
  animation-name: confetti15;
}

.item:nth-child(2) .icon,
.item:nth-child(11) .icon,
.item:nth-child(12) .icon {
  animation-duration: 0.9s;
}

.item:nth-child(3) .icon,
.item:nth-child(13) .icon,
.item:nth-child(14) .icon {
  animation-duration: 0.6s;
}

.item:nth-child(5) .icon,
.item:nth-child(15) .icon {
  animation-duration: 1.2s;
}

.item:nth-child(6) .icon {
  animation-duration: 0.4s;
}

@keyframes confetti1 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-90px, -70px);
    opacity: 1;
  }
  40% {
    transform: translate(-140px, -20px);
    opacity: 1;
  }
  60% {
    transform: translate(-210px, 10px);
  }
  100% {
    transform: translate(-290px, 120px);
    opacity: 0;
  }
}

@keyframes confetti2 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-70px, 60px);
    opacity: 1;
  }
  40% {
    transform: translate(-100px, 90px);
  }
  60% {
    transform: translate(-120px, 130px);
    opacity: 1;
  }
  100% {
    transform: translate(-130px, 230px);
    opacity: 0;
  }
}

@keyframes confetti3 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-100px, 40px);
  }
  40% {
    transform: translate(-120px, 60px);
  }
  60% {
    transform: translate(-160px, 90px);
    opacity: 1;
  }
  100% {
    transform: translate(-190px, 270px);
    opacity: 0;
  }
}

@keyframes confetti4 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(90px, 40px);
  }
  40% {
    transform: translate(120px, 60px);
  }
  60% {
    transform: translate(150px, 110px);
    opacity: 1;
  }
  100% {
    transform: translate(180px, 180px);
    opacity: 0;
  }
}

@keyframes confetti5 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(60px, 70px);
  }
  40% {
    transform: translate(80px, 80px);
  }
  60% {
    transform: translate(100px, 130px);
    opacity: 1;
  }
  100% {
    transform: translate(130px, 220px);
    opacity: 0;
  }
}

@keyframes confetti6 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-50px, -80px);
  }
  40% {
    transform: translate(-80px, -130px);
  }
  60% {
    transform: translate(-160px, -60px);
    opacity: 1;
  }
  100% {
    transform: translate(-240px, 90px);
    opacity: 0;
  }
}

@keyframes confetti7 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(70px, -70px);
  }
  40% {
    transform: translate(90px, -90px);
  }
  60% {
    transform: translate(130px, -60px);
    opacity: 1;
  }
  100% {
    transform: translate(170px, 100px);
    opacity: 0;
  }
}

@keyframes confetti8 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(100px, 0);
  }
  40% {
    transform: translate(130px, -10px);
  }
  60% {
    transform: translate(150px, -5px);
    opacity: 1;
  }
  100% {
    transform: translate(220px, 75px);
    opacity: 0;
  }
}

@keyframes confetti9 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(100px, -40px);
  }
  40% {
    transform: translate(150px, -50px);
  }
  60% {
    transform: translate(190px, -30px);
    opacity: 1;
  }
  100% {
    transform: translate(240px, 10px);
    opacity: 0;
  }
}

@keyframes confetti10 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(20px, -90px);
  }
  40% {
    transform: translate(60px, -120px);
  }
  60% {
    transform: translate(140px, -90px);
    opacity: 1;
  }
  100% {
    transform: translate(180px, 40px);
    opacity: 0;
  }
}

@keyframes confetti11 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-70px, -30px);
  }
  40% {
    transform: translate(-100px, -40px);
  }
  60% {
    transform: translate(-140px, -40px);
    opacity: 1;
  }
  100% {
    transform: translate(-200px, 60px);
    opacity: 0;
  }
}

@keyframes confetti12 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-80px, 20px);
  }
  40% {
    transform: translate(-110px, 10px);
  }
  60% {
    transform: translate(-150px, 40px);
    opacity: 1;
  }
  100% {
    transform: translate(-180px, 120px);
    opacity: 0;
  }
}

@keyframes confetti13 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-90px, -70px);
  }
  40% {
    transform: translate(-120px, -90px);
  }
  60% {
    transform: translate(-160px, -80px);
    opacity: 1;
  }
  100% {
    transform: translate(-250px, -10px);
    opacity: 0;
  }
}

@keyframes confetti14 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(-30px, -80px);
  }
  40% {
    transform: translate(-50px, -110px);
  }
  60% {
    transform: translate(-80px, -120px);
    opacity: 1;
  }
  100% {
    transform: translate(-170px, 30px);
    opacity: 0;
  }
}

@keyframes confetti15 {
  0% {
    opacity: 0;
  }
  20% {
    transform: translate(0, -90px);
  }
  40% {
    transform: translate(-30px, -120px);
  }
  60% {
    transform: translate(-60px, -130px);
    opacity: 1;
  }
  100% {
    transform: translate(-130px, 100px);
    opacity: 0;
  }
}

@keyframes gift {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
