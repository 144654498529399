.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.videoWrap:not(.blurred) {
  width: inherit;
  height: inherit;
}

.video {
  display: flex;
  margin: auto;
  background: var(--themeCardLight);
}

.blurred {
  filter: blur(var(--themeBlurMedium));
}

.cover.blurred,
.contain.blurred {
  width: calc(100% + var(--themeBlurMedium) * 2);
  height: calc(100% + var(--themeBlurMedium) * 2);
  transform: translate(calc(var(--themeBlurMedium) * -1), calc(var(--themeBlurMedium) * -1));
}

.сontainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.content {
  composes: сontainer;
  top: 40px;
  left: 20px;
  right: 20px;
}

.contentCentered {
  composes: сontainer;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 20px;
}

.titleWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 40px;
  z-index: 1;
}

.title {
  display: flex;
  align-items: center;
  line-height: 20px;
  white-space: normal;
}

.actions {
  width: 100%;
}

.spacedActions {
  padding: 0 40px;
}

.action {
  max-width: none;
}
