.video {
  border-radius: inherit;
}

.fullScreen {
  max-height: var(--appHeight);
  max-width: var(--appWidth);
}

.parentWidth {
  max-width: 100%;
  max-height: 100%;
}

.shadow {
  box-shadow: var(--themeShadow1);
}

@media (max-width: 736px) {
  .video {
    min-height: 100%;
  }

  /* csslint ignore:start */
  .video::-webkit-media-controls-overlay-play-button {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    margin-top: 0;
  }

  /* csslint ignore:end */
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contain {
  object-fit: contain;
}
