.container {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
}

.container:last-child {
  margin-bottom: 0;
}

.iconHolder {
  flex-shrink: 0;
  display: flex;
}

.content {
  flex-grow: 1;
  position: relative;
  min-width: 0;
}

.inner {
  position: relative;
}

.title {
  display: flex;
  align-items: center;
}

.title:empty {
  display: none;
}

.spacedLeft {
  margin-left: 50px;
}

.withBorder {
  border-bottom: 1px solid var(--themeDivider);
}

.withoutExternalBottomSpacing {
  margin-bottom: 0;
}

.small .spacedLeft {
  margin-left: 40px;
}
