.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--appWidth);
  height: 100%;
}

.container:focus {
  outline: 0;
}

.zoomableContainer {
  height: 100%;
}

.photoWrap {
  height: 100%;
  width: var(--appWidth);
  max-width: 1000px;
}

.photo {
  height: 100%;
}

.blurredPhotoContainer {
  height: 100%;
}

.blurredPhoto {
  /* Add  flex for correct view in Ios 10 */
  display: flex;
  align-items: center;
  width: calc(var(--appWidth) / 2);
}

@media (max-width: 736px) {
  .blurredPhoto {
    width: var(--appWidth);
  }
}
