.checkbox {
  margin-left: 4px;
  margin-bottom: -3px;
  margin-top: -3px;
}

@media screen and (min-width: 712px) {
  .button {
    max-width: 200px;
    margin: 0 auto;
  }
}

@media screen and (max-height: 500px) {
  .row {
    margin: 10px 0;
  }
}
