.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--appWidth);
  height: 100%;
}

.container:focus {
  outline: 0;
}
