.container {
  text-align: center;
  margin: 0 -5px -10px;
}

.item {
  text-align: left;
  margin: 0 5px 10px;
  height: 30px;
  vertical-align: middle;
  transition: var(--themeTransition);
  will-change: transform;
}

.item:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .item:hover {
    opacity: 1;
  }
}

.amex,
.visa {
  height: 35px;
}

.dci {
  width: 82px;
}

.discover {
  width: 56px;
}

.jcb {
  width: 35px;
}

.garantee,
.moneyback {
  width: 96px;
}

.mastercard {
  width: 55px;
}

.amipci {
  width: 71px;
}

.lock {
  width: 18px;
}

.card,
.trustly {
  width: 78px;
}

.trustly {
  height: 24px;
}

.bancontact,
.visacardcompliance,
.ministop {
  width: 44px;
}

.bccard {
  width: 102px;
}

.cartasi {
  width: 72px;
}

.postepay {
  width: 61px;
}

.switch {
  width: 30px;
}

.hipercard {
  width: 58px;
}

.mercardo {
  width: 81px;
}

.cartebancaire {
  width: 38px;
}

.elo {
  width: 53px;
}

.comodo {
  width: 79px;
}

.letsencrypt {
  width: 40px;
}

.applepay,
.mastercardcompliance {
  width: 32px;
}

.applepay {
  height: 20px;
}

.electroniccash {
  width: 25px;
}

.sepalastschrift {
  width: 75px;
}

.deutschequalitat {
  width: 66px;
}

.lawson {
  width: 75px;
}

.familymart {
  width: 90px;
}

.seicomart {
  width: 105px;
}

.przelewy24 {
  width: 80px;
  height: 27px;
}

.ideal {
  width: 45px;
}

.pcicertified {
  width: 83px;
}
