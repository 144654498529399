.centered {
  text-align: center;
}

.header {
  display: flex;
  justify-content: center;
  position: relative;
}

.subIcon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(40%);
}

.animation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}
