.content {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
}

.oneColumn {
  flex-direction: column;
}

.description {
  margin-left: 20px;
  font: var(--themeText);
}

.oneColumn .description {
  margin: 4px 0 0 0;
  font: var(--themeTextSmall);
}
