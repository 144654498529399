.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.content {
  position: relative;
  width: 100%;
  z-index: 2;
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  padding: 0 10px 4px 0;
}

.actions {
  width: 100%;
}

.action {
  max-width: none;
}
