.wrapper {
  height: 100%;
  width: 100%;
}

.wrapper iframe {
  width: auto !important; /* stylelint-disable-line */
  display: flex;
  transform: scale(0.6);
  transform-origin: 0 30%;
  position: absolute; /** Need for correct display in FF **/
}

.roundedWithoutActionsAppearance iframe {
  transform: scale(0.7);
}
