.accordion {
  background-color: var(--themeCardLight);
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.accordion,
.accordionContent {
  /* translateZ(0) is fix for render on iphone SE */
  transform: translateZ(0);
}
