.users {
  height: 80px;
  overflow: hidden;
  border-bottom: 1px solid var(--themeDivider);
  width: 260px;
  margin: 0 auto;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}
