.container {
  display: flex;
  flex-wrap: wrap;
}

.inline {
  flex-wrap: nowrap;
}

/* Align */
.between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

/* Gift item */
.gift {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.default {
  width: 92px;
  margin-bottom: 20px;
}

.small {
  width: 85px;
  padding-bottom: 10px;

  /* additional 1px spacing for active border by design */
  margin: 0 1px 10px 1px;
  cursor: pointer;
}

.inlineItem {
  flex-shrink: 0;
}

.active {
  box-shadow: 0 0 0 2px var(--skinPrimary);
  border-radius: var(--themeRadius);
}

.last {
  /* Need to normal gaps from items in last line */
  flex: auto;
  padding: 0;
  margin: 0;
}

.image {
  margin-bottom: 10px;
}

/* Need for correct view for small mobile screens */
@media (max-width: 360px) {
  .gift {
    width: 85px;
  }
}
