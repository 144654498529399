.avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
}

.photo {
  position: relative;
  border-radius: var(--themeRadiusLarge);
  background: var(--themeCard);
  overflow: hidden;
}

.photo:nth-child(2) {
  z-index: 2;
  width: 120px;
  height: 120px;
  margin-left: -20px;
  margin-right: -20px;
  box-sizing: content-box;
  border: 2px solid var(--themeCard);
}

.photo:first-child,
.photo:last-child {
  width: 80px;
  height: 80px;
}

.small .photo:first-child,
.small .photo:last-child {
  width: 60px;
  height: 60px;
}

.small .photo:nth-child(2) {
  width: 80px;
  height: 80px;
  margin-left: -10px;
  margin-right: -10px;
}

.animation.photo:nth-child(1) {
  animation: slideToLeft 400ms ease-out 1.2s normal both;
}

.animation.photo:nth-child(2) {
  animation: emergence 500ms ease-out 700ms normal both;
}

.animation.photo:nth-child(3) {
  animation: slideToRight 300ms ease-out 1.4s normal both;
}

@keyframes slideToLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes emergence {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  30% {
    opacity: 1;
  }

  80% {
    transform: scale(1.14);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideToRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
