.icon {
  position: absolute;
  color: var(--skinPrimary);
}

.icon:nth-child(1) {
  font-size: 24px;
  top: 32%;
  left: 20%;
  transform: rotate(-30deg);
  animation: priorityManSuccess1 6s ease-in-out 0s both infinite;
}

.icon:nth-child(2) {
  font-size: 30px;
  top: 9%;
  left: 21%;
  transform: rotate(-30deg);
  animation: priorityManSuccess1 6s ease-in-out 1.2s both infinite;
}

.icon:nth-child(3) {
  font-size: 24px;
  top: 3%;
  left: 46%;
  animation: priorityManSuccess3 6s ease-in-out 0.4s both infinite;
}

.icon:nth-child(4) {
  font-size: 30px;
  top: 9%;
  right: 21%;
  transform: rotate(30deg);
  animation: priorityManSuccess2 6s ease-in-out 1.6s both infinite;
}

.icon:nth-child(5) {
  font-size: 24px;
  top: 32%;
  right: 20%;
  transform: rotate(30deg);
  animation: priorityManSuccess2 6s ease-in-out 0.8s both infinite;
}

@keyframes priorityManSuccess1 {
  from {
    opacity: 0;
    transform: rotate(140deg);
  }
  9%,
  31% {
    opacity: 0.5;
    transform: rotate(-30deg);
  }
  44%,
  to {
    opacity: 0;
    transform: rotate(-30deg);
  }
}

@keyframes priorityManSuccess2 {
  from {
    opacity: 0;
    transform: rotate(270deg);
  }
  9%,
  31% {
    opacity: 0.5;
    transform: rotate(30deg);
  }
  44%,
  to {
    opacity: 0;
    transform: rotate(30deg);
  }
}

@keyframes priorityManSuccess3 {
  from {
    opacity: 0;
    transform: rotate(270deg);
  }
  9%,
  31% {
    opacity: 0.5;
    transform: rotate(0deg);
  }
  44%,
  to {
    opacity: 0;
    transform: rotate(0deg);
  }
}
