/**
 * Override default button background since current button is not integrated in design system.
 */
.button {
  background: #000 !important;  /* stylelint-disable-line */
}

.buttonInverse {
  background: #fff !important;  /* stylelint-disable-line */
}

.image {
  width: auto;
  height: 20px;
  vertical-align: middle;
}
