/**
 * These styles are needed to keep the footer at the bottom of the page in an unauthorized area.
 * @see HeaderTopWrapper.js and HeaderDisplayer.js
 */
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0; /* Fixes a bug on ios on PP when displaying a popup */
}

.zIndex {
  z-index: 25; /* For cases when header is not sticky */
}

/**
 * @see LocationDependentTopWrapper.js
 */
.shiftedTop {
  margin-top: -20px;
}
