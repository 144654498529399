.notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 500; /* Must be the first one, but under messenger */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.content {
  display: flex;
  align-items: center;
}

.title {
  max-width: 40%;
}

.description {
  min-width: 170px;
}

.phone {
  white-space: nowrap;
}
