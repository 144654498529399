.action {
  text-align: center;

  /**
   * Without this rule animation will add
   * vertical scroll inside popup.
   * @see ButtonWithSuccessMessage.js
   */
  position: relative;
}

.verticalAction {
  composes: action;
  width: 100%;
}

.horizontalAction {
  composes: action;
  width: 50%;
}

/**
 * Custom block for spacing that doesn't fit any logic.
 * Uses the same breakpoint as spacing.
 * @see Spacing.css
 */
.motivation {
  /**
   * Used for setting online status on user (is uses 'currentColor' feature)
   */
  color: var(--themeCardLight);
}

.resendLink {
  cursor: pointer;
}
