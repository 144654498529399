.banner {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.iframe {
  /* iframe occupy 100% of the wrapper,
  and the style banner should come from the backend so that there are no crutches on the front */
  width: 100% !important; /* stylelint-disable-line */
  display: inline-block;
  vertical-align: middle;
  border: none;
  margin: 0;
  padding: 0;
}

.hidden {
  /* Should be hidden until iframe was loaded */
  display: none;
}

.inlineBanner {
  display: inline-flex;
  vertical-align: top;
}

/**
 * To display the banner correctly for the user.
 * @see TargetUserProfileAsListWithToolbar.js
 */
.withBorder {
  padding: 20px 0;
  border-bottom: 1px solid var(--themeDivider);
}

.fullsize {
  height: 100%;
  display: flex;
  align-items: center;
}

.fullsize .wrap {
  flex-grow: 1;
  margin-top: 20px;
}
