.overlay {
  pointer-events: none;
}

.overlayWrap {
  composes: overlay;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  z-index: 0;
}
