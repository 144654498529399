.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: inherit;
  background: inherit;
}

.sideSpacing {
  padding-left: 10px;
  padding-right: 10px;
}

.fullWidth {
  display: block;
}

.container {
  max-width: 480px;
  justify-content: center;
  width: 100%;
}

.fullSize {
  width: 100%;
  height: 100%;
}

.action {
  width: 50%;
}
