.countdown {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.timerRow {
  display: flex;
  justify-content: center;
}
