.photo {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.shadow {
  box-shadow: var(--themeShadow1);
}

.round {
  border-radius: var(--themeRadiusLarge);
  overflow: hidden;

  /* fix bug with rendering photo on galaxy S5 */
  transform: translateZ(0);
}

.clickable {
  cursor: pointer;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.withBlurAnimation {
  animation: blur 4s infinite linear both;
}

@keyframes blur {
  0% {
    filter: blur(var(--themeBlurHeavy));
  }
  25% {
    filter: blur(0);
  }
  50% {
    filter: blur(0);
  }
  100% {
    filter: blur(var(--themeBlurHeavy));
  }
}
