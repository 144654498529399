.icon {
  position: absolute;
  font-size: 33px;
  color: var(--skinSuccess);
  opacity: 0.5;
}

.icon:nth-child(1) {
  animation: superBoostSuccess1 8.1s ease-out 0s both infinite;
}

.icon:nth-child(2) {
  animation: superBoostSuccess2 8.1s ease-out 0.4s both infinite;
}

.icon:nth-child(3) {
  animation: superBoostSuccess3 8.1s ease-out 0.8s both infinite;
}

.icon:nth-child(4) {
  animation: superBoostSuccess4 8.1s ease-out 1.2s both infinite;
}

.icon:nth-child(5) {
  animation: superBoostSuccess5 8.1s ease-out 1.6s both infinite;
}

.icon:nth-child(6) {
  animation: superBoostSuccess6 8.1s ease-out 2s both infinite;
}

@keyframes superBoostSuccess1 {
  from,
  6%,
  to {
    top: -14%;
    left: 39%;
  }
  12% {
    top: 26%;
    left: -22%;
  }
  18% {
    top: -14%;
    left: -20%;
  }
}

@keyframes superBoostSuccess2 {
  from,
  6%,
  to {
    top: -14%;
    left: 67%;
  }
  12% {
    top: 39%;
    left: -34%;
  }
  18% {
    top: -14%;
    left: -20%;
  }
}

@keyframes superBoostSuccess3 {
  from,
  6%,
  to {
    top: -14%;
    left: 92%;
  }
  12% {
    top: 58%;
    left: -34%;
  }
  18% {
    top: -14%;
    left: -20%;
  }
}

@keyframes superBoostSuccess4 {
  from,
  6%,
  to {
    top: -14%;
    left: 123%;
  }
  12% {
    top: 74%;
    left: -33%;
  }
  18% {
    top: -14%;
    left: -20%;
  }
}

@keyframes superBoostSuccess5 {
  from,
  6%,
  to {
    top: -14%;
    left: 151%;
  }
  12% {
    top: 88%;
    left: -33%;
  }
  18% {
    top: -14%;
    left: -20%;
  }
}

@keyframes superBoostSuccess6 {
  from,
  6%,
  to {
    top: -14%;
    left: 176%;
  }
  12% {
    top: 94%;
    left: -48%;
  }
  18% {
    top: -14%;
    left: -20%;
  }
}
