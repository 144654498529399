.widget {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 320px) {
  .widget {
    width: 320px;
    margin: 0 -5px 20px;
  }

  .widget iframe {
    transform: scale(0.9);
  }
}
