.wrap {
  background-color: var(--skinPrimary);
}

.iconsWrap {
  display: flex;
  justify-content: center;
  height: 30px;
  margin-bottom: 30px;
}

.photo {
  position: absolute;
  width: 60px;
  height: 60px;
  margin-left: 40px;
}

.icon {
  position: absolute;
  margin-right: 40px;
}
