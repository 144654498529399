.iconOnSide {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  z-index: 2;
}

.overlay::before {
  position: absolute;
  top: -50px;
  left: -50px;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  filter: blur(var(--themeBlurHeavy));
  pointer-events: none;
  opacity: 0.5;
  content: "";
}

.leftOnSideIcon {
  composes: iconOnSide;
  left: 10px;
}

.rightOnSideIcon {
  composes: iconOnSide;
  right: 10px;
}

.iconOnTop {
  position: fixed;
  top: 10px;
  z-index: 2;
}

.leftOnTopIcon {
  composes: iconOnTop;
  right: 50%;
  margin-right: 20px;
}

.rightOnTopIcon {
  composes: iconOnTop;
  left: 50%;
  margin-left: 20px;
}
