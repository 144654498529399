.animation {
  animation: moreLikes 7s infinite ease-in-out;
  will-change: transform;
}

@keyframes moreLikes {
  from {
    font-size: inherit; /* Fix for animation on popup */
  }
  from,
  20%,
  40%,
  60%,
  to {
    transform: scale(1);
  }
  10%,
  30%,
  50% {
    transform: scale(1.2);
  }
}
