.icon {
  transform: translateZ(0); /* Fix for animation on iOS */
}

.animation {
  animation: topChat 4s infinite linear;
  will-change: transform;
}

@keyframes topChat {
  from {
    font-size: inherit; /* Fix for animation on popup */
  }
  from,
  45%,
  53%,
  63%,
  to {
    transform: rotateY(0);
  }
  50%,
  58% {
    transform: rotateY(90deg);
  }
}
