.price {
  position: relative;
  display: flex;
  align-items: center;
}

.price::after {
  content: '';
  position: absolute;
  margin-top: -1px; /* to align strikethrough properly */
  top: 50%;
  left: 10px;
  right: 8px; /* to align with actual price when displayed at line above */
  height: 2px;
  background: var(--skinDanger);
}
