.price {
  direction: ltr /* rtl:ignore */;
  text-align: left /* rtl:ignore */;
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 0;
  margin: 0 8px 0 20px;
  border-radius: 0 var(--themeRadius) var(--themeRadius) 0;
  background: var(--themeCardLight);
  line-height: 1;
  height: 36px;
}

.price.rounded {
  border-radius: var(--themeRadius);
  margin-left: 2px;
  padding: 4px;
}

.price.rounded.withSpacing {
  margin-left: 10px;
}

.price.highlighted {
  background: var(--skinPrimaryLite);
}

.price.transparent {
  background: transparent;
  margin-left: 0;
}

.inner {
  direction: ltr /* rtl:ignore */;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;
}

.arrow {
  position: absolute;
  top: 0;
  width: 19px;
  height: 100%;
  overflow: hidden;
  right: 100%;
}

.arrow::before {
  content: '';
  width: 32px;
  height: 32px;
  position: absolute;
  top: 2px;
  left: 6px;
  transform: rotate(45deg);
  border-radius: 10px 0 10px 4px;
  background: var(--themeCardLight);
  will-change: transform;
}

.price.highlighted .arrow::before {
  background: var(--skinPrimaryLite);
}

.price.transparent .arrow::before {
  background: transparent;
}

.hi {
  font: var(--themeH1);
  font-weight: bold;
  padding: 0 2px;
}

.rowPrice {
  composes: hi;
  font-weight: normal;
}

.currency {
  position: relative;
}

.lo {
  position: relative;
  top: 2px;
}

.period {
  position: relative;
  bottom: 2px;
  white-space: nowrap;
}

.currency,
.lo {
  font: var(--themeH4);
}

.lo:empty + .period {
  top: 2px;
}

.suffix {
  align-self: flex-start;
}

.suffixCentered {
  align-self: center;
}

.suffixCentered .lo {
  top: 0;
}

.discount {
  position: absolute;
  right: -4px;
  top: -12px;
  z-index: 2;
}
