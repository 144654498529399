/* Pasted elements from translation @see popupNaughtyMode.text.rp-restriction-part-1 */
.restriction span,
.restriction h4 {
  display: block;
  margin-bottom: 10px;
}

.action {
  min-width: 200px;
  margin: 0 auto;
}
