.wrapper {
  width: 100%;
  z-index: 1000;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.top .rounded {
  border-bottom-left-radius: var(--themeRadiusBar);
  border-bottom-right-radius: var(--themeRadiusBar);
}

.bottom .rounded {
  border-top-left-radius: var(--themeRadiusBar);
  border-top-right-radius: var(--themeRadiusBar);
}

.floating.rounded {
  border-radius: var(--themeRadius);
}

.top .stronglyRounded {
  border-bottom-left-radius: var(--themeRadiusLarge);
  border-bottom-right-radius: var(--themeRadiusLarge);
}

.bottom .stronglyRounded {
  border-top-left-radius: var(--themeRadiusLarge);
  border-top-right-radius: var(--themeRadiusLarge);
}

.floating.stronglyRounded {
  border-radius: var(--themeRadiusLarge);
}

.floating {
  margin: 10px;
}

.top .floating {
  margin-bottom: 0;
}

.bottom .floating {
  margin-top: 0;
}

.empty {
  margin-top: 0;
  margin-bottom: 0;
}

.bottom.sticky,
.bottom.fixed {
  will-change: transform;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.sticky {
  position: sticky;
}

.fixed {
  position: fixed;
}

/* Fix for opened keyboard with sender form on android browser */
.relative {
  position: relative;
}

.toolbarPolyfill {
  height: var(--topWrapperHeight);
}

.headerPolyfill {
  height: var(--headerHeight);
  flex-shrink: 0;
}
