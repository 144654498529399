.banner {
  text-align: center;
}

.percent {
  /* Remove after adding variable */
  font-size: 60px;
  line-height: 70px;
  color: var(--skinPrimary);
}

.sites {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 120px;
  height: 30px;
}

.image {
  display: block;
  max-height: 100%;
  max-width: inherit;
}

.wrapLogo {
  display: flex;
  align-items: center;
}

.plus {
  margin: 0 4px;
}

.action {
  min-width: 180px;
  margin: 0 auto;
}
