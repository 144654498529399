.wrap {
  /** Animation delay to wait popup opening animation */
  --expandSearchLimitsPopupAnimationDelay: 800ms;
}

.users {
  width: 260px;
  margin: -60px auto 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.expired .users {
  margin-top: -50px;
  height: 90px;
  animation: expiredUsers 400ms var(--expandSearchLimitsPopupAnimationDelay) both;
}

.overlay {
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  height: 90px;
  animation: fadein 400ms var(--expandSearchLimitsPopupAnimationDelay) both reverse;
}

.expired .overlay {
  animation-direction: normal;
}

.user {
  position: relative;
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  border-radius: var(--themeRadius);
}

.user:nth-child(4),
.user:nth-child(5),
.user:nth-child(6) {
  animation: fadein 300ms both;
}

.user:nth-child(4) {
  animation-delay: var(--expandSearchLimitsPopupAnimationDelay);
}

.user:nth-child(5) {
  animation-delay: calc(var(--expandSearchLimitsPopupAnimationDelay) + 300ms);
}

.user:nth-child(6) {
  animation-delay: calc(var(--expandSearchLimitsPopupAnimationDelay) + 600ms);
}

.expired .user:nth-child(4),
.expired .user:nth-child(5),
.expired .user:nth-child(6) {
  animation-direction: reverse;
  animation-delay: var(--expandSearchLimitsPopupAnimationDelay);
}

.login {
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  animation: content 400ms var(--expandSearchLimitsPopupAnimationDelay) both;
}

.expired .content {
  transform: none;
  animation: none;
}

.timer {
  animation: fadein 400ms var(--expandSearchLimitsPopupAnimationDelay) both;
}

@keyframes content {
  from {
    transform: translateY(-90px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes expiredUsers {
  from {
    transform: translateY(-10px);
    margin-bottom: 120px;
  }
  to {
    transform: translateY(0);
    margin-bottom: 30px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
