.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  position: relative;
  min-width: 20px;
}

.checkbox::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 50%;
}

.animated::after {
  animation: pulse 1.5s infinite;
}

.animated:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--skinPrimary);
    opacity: 0.4;
  }
  70% {
    box-shadow: 0 0 0 20px var(--skinPrimary);
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0 var(--skinPrimary);
    opacity: 0;
  }
}
