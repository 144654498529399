.container {
  position: relative;
  overflow: hidden;
}

/* Made bottom shadow of element be visible */
.fixedHeight:not(:empty) {
  box-sizing: content-box;
  min-height: var(--toolbarFixedHeight);
  padding-bottom: 10px;
  margin-bottom: -10px;
}

.notification {
  position: absolute;
  width: 100%;
}
