.widget {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  position: relative;
  margin: 0 calc(var(--searchWidgetSpacingHorizontal) + var(--searchWidgetHorizontalAdjustment)) var(--searchWidgetSpacingVertical);

  /* Since most of widgets are rounded inside such spacing and we should allow to inherit radiuses */
  border-radius: var(--themeRadius);
}

.withoutBottom {
  margin-bottom: 0;
}

.widget:empty {
  display: none;
}

/* Size modifications for widget */
.fixed {
  width: var(--searchWidgetFixedWidth);
}

.fixedFull {
  width: var(--searchWidgetFixedWidth);
}

/**
 * Different widget appearances have different heights for situations, where elements have fixed size
 *
 * @todo
 * Find a way how to discard using fixed height for widgets
 * (currently - motivation ones) and use flexible one based on other widgets.
 */
.fixedFull.allInCard,
.fixedFull.allInCardWithBlurredActions {
  height: 240px;
}

.fixedFull.floatingButtons {
  height: 260px;
}

.fixedFull.flatWithHashtagsAndFlirtAction {
  height: 441px;
}

.fixedFull.roundedWithOnlineAsText {
  height: 226px;
}

/**
 * Add height to rm banners for correct displaying iframes based on themes
 */
.fixedFull.rounded {
  height: 238px;
}

.fixedFull.flatWithHashtagsAndFlirtAction.rmBanner,
.fixedFull.roundedWithOnlineAsText.rmBanner,
.fixedFull.rounded.rmBanner {
  margin-top: 1px;
}

.fixedFull.allInCardWithRoundPhoto {
  height: 274px;
}

.fixedFull.allInCardWithRoundPhoto.rmBanner {
  margin-top: 1px;
  height: 273px;
}

.fixedFull.roundedWithoutActions {
  height: var(--searchWidgetFixedWidth);
}

.fixedFull.roundedWithoutActions.rmBanner {
  margin-top: 1px;
  height: calc(var(--searchWidgetFixedWidth) - 1px);
}

/**
 * Widget width based on passed property.
 */
.fluid {
  width: calc(100% - (var(--searchWidgetSpacingHorizontal) + var(--searchWidgetHorizontalAdjustment)) * 2);
}

.fluidHalf {
  width: calc(50% - (var(--searchWidgetSpacingHorizontal) + var(--searchWidgetHorizontalAdjustment)) * 2);
}

.fullWidth {
  width: calc(100% - (var(--searchWidgetSpacingHorizontal) + var(--searchWidgetHorizontalAdjustment)) * 2);
}

/* Alignment */
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/**
 * Special size
 */
.fullscreen {
  width: 100%;
  height: 100%;
}

@media (min-width: 420px) {
  .fluid:not(.flatWithHashtagsAndFlirtAction):not(.fullscreen) {
    width: calc(50% - (var(--searchWidgetSpacingHorizontal) + var(--searchWidgetHorizontalAdjustment)) * 2);
  }
}

@media (min-width: 480px) {
  .fluidHalf {
    width: calc(100% / 3 - (var(--searchWidgetSpacingHorizontal) + var(--searchWidgetHorizontalAdjustment)) * 2);
  }
}

@media (min-width: 600px) {
  .fluidHalf {
    width: calc(25% - (var(--searchWidgetSpacingHorizontal) + var(--searchWidgetHorizontalAdjustment)) * 2);
  }
}
