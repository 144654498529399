.container {
  display: flex;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  justify-content: center;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconHelp {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  min-width: 40px;
  margin-right: -10px;
  margin-left: 10px;
}

.pmaTooltip {
  width: 240px;
}
