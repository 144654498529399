.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  z-index: 1;
  transform: translateZ(0);
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
