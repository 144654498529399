.wrap {
  position: relative;
  padding-top: calc(100% * 4 / 3);
  z-index: 15;
}

.image,
.content {
  position: absolute;
}

.image {
  top: 0;
}

.content {
  bottom: 0;
}

.overlay {
  height: 70%;
}
