:root {
  --toolbarFixedHeight: 60px;
}

.wrapper {
  /**
   * prevent callout menu after long press
   * yes, in safari after long press on the chat message, callout menu triggered in this place
   */
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Webkit */
  border-radius: inherit;
}

.fixedHeight {
  height: var(--toolbarFixedHeight);
}

.wrapper.inverse {
  background: var(--skinHeader);
}

.transparent {
  background: none;
}

.container {
  height: 100%;
  border-radius: inherit;
}

.toolbar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: inherit;
}

.center {
  justify-content: center;
  text-align: center;
}

.action {
  flex-shrink: 0;
}

.actionleft {
  padding-right: 10px;
}

.actionright {
  order: 1;
}

.content {
  flex-grow: 1;
  justify-content: flex-start;
  height: 100%;
  min-width: 0;
  position: relative;
  z-index: 100; /* For non-sticked sub-header */
  border-radius: inherit;
}

.center .content {
  justify-content: center;
}

/* Used for centering tabs in case if back button exists */
.sideRight,
.sideLeft {
  min-width: 50px;
  height: 100%;
}
