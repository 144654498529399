.success {
  min-height: 40px; /* Since it replaces in most of cases button, and button have 40px height */
  display: inline-flex;
  align-items: center;
  text-align: left;
}

.icon {
  padding-right: 20px;
}
