/**
 * calc(0 * 1px) - need for prevent remove units by prettier
 * without units calc(100px + var(--topWrapperHeight)) doesn't work
 */
:root {
  --appHeight: calc(0 * 1px);
  --appWidth: calc(0 * 1px);
  --headerHeight: calc(0 * 1px);
  --topWrapperHeight: calc(0 * 1px);

  /* for snapchat and tiktok keyboard */
  --inAppSpacedBottom: calc(var(--appHeight) - 350px);

  /**
   * For Miui Browser.
   * 60 - the height of the bottom toolbar of the browser,
   * it needs to be removed.
   */
  --inAppSpacedBottomForMiui: calc(var(--appHeight) - 350px + 60px);
}

@media (max-height: 680px) {
  :root {
    --inAppSpacedBottom: calc(var(--appHeight) - 290px);
    --inAppSpacedBottomForMiui: calc(var(--appHeight) - 290px + 60px);
  }
}

/* Huawei p series 20 30 pro and lite and Samsung s8 / s9 / s8+ / s9+ */
@media (height: 622px) {
  :root {
    --inAppSpacedBottom: calc(var(--appHeight) - 310px);
    --inAppSpacedBottomForMiui: calc(var(--appHeight) - 310px + 60px);
  }
}

*,
*::before,
*::after {
  /**
   * Change global box-sizing model. Because most of items need this model
   */
  box-sizing: border-box;

  /**
   * Remove default Chrome accessibility feature since all people hates that :(
   */
  outline: none;
}

html {
  overflow: auto;
  min-height: var(--appHeight);

  /** Mobile specific styles */
  -webkit-tap-highlight-color: transparent;

  /** !important - need for reset normalize.css styles */
  /* stylelint-disable-next-line declaration-no-important */
  -webkit-text-size-adjust: auto !important;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  margin: 0;
  display: flex;
  min-width: 0;
}

body {
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

/* Basic layout styles */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Typography styles */
a {
  cursor: pointer;
  text-decoration: none;
  color: var(--skinPrimary);
}

a,
button,
label,
select,
input,
textarea {
  -webkit-tap-highlight-color: var(--themeCardLight);
}

/* rtl:begin:ignore */
/**
 * LTR direction forced for RTL locations to fix next problems:
 * - email addresses are broken by "@" symbol, but shouldn't
 * - card number is broken by spaces "dddd dddd..." , but shoudn't
 * TODO: may be use non-breaking-space "\u202f" like this:
 * 'visa': '0000\u202f0000\u202f0000\u202f0000\u202f000',
 * For email find similar soultions.
 *
 * For LTR locations force LTR direction to prevent IOs feature
 * "LTR-to-RTL" local input switcher. @see FE-23322
 */
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[name="email"] {
  direction: ltr;
}

[dir="rtl"] input[type="text"],
[dir="rtl"] input[type="password"],
[dir="rtl"] input[type="tel"],
[dir="rtl"] input[type="email"],
[dir="rtl"] input[name="email"] {
  text-align: right;
}

/* rtl:end:ignore */

p {
  margin-top: 0;
  margin-bottom: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: inherit;
}

h1 {
  font: var(--themeH1);
}

h2 {
  font: var(--themeH2);
}

h3 {
  font: var(--themeH3);
}

h4 {
  font: var(--themeH4);
}

/* Basic tables styles */
table {
  width: 100%;
  border: 0;
  border-collapse: collapse;
  border-bottom: 1px solid var(--themeDivider);
}

th {
  text-align: left;
  font-weight: normal;
  color: var(--themeTextColorMuted);
  padding: 10px;
}

td {
  padding: 10px;
  border-top: 1px solid var(--themeDivider);
}

/* Modules */

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-width: 0;
  min-height: 100%;
}

/**
 * Is required for the case when we have "header" attached to bottom of screen.
 * When all content isn't initialized yet, we should push anyway content down
 */
.footer {
  margin-top: auto;
}

/**
 * Used for adding advanced bottom
 * spacing when user open our sites through tiktok browser wit bottom navbar
 * and overlap sender form in chat
 */
.tiktokSpacing {
  height: 70px;
}

.viewport {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
}

/**
 * Disable interface on popup-page.
 * It helps to avoid unwanted navigation and 'Multiple history.push' errors.
 * @see RouteWithPopupDisplayer
 * @see ROUTES_WITH_POPUP
 * @see .layout of PopupLayout.css
 */
.disabled {
  pointer-events: none;
}

/**
 * Popup related helpers
 */
:global(.lock-scrolling) {
  overflow: hidden;

  /* Works only if children don't have overflow specified */
  touch-action: none;
}

.scrollable {
  display: block;
  overflow-y: auto;
  height: var(--appHeight);
  -webkit-overflow-scrolling: touch;
}

.spacedBottom {
  height: var(--inAppSpacedBottom);
  transition: var(--themeTransition);
  flex-grow: 0;
}

/* Fixes bug related to "position: sticky" for "senderForm" in InApp */
.transform {
  transform: scale(1);
}

:global(.lock-scrolling) .spacedBottom {
  overflow: hidden;
}

.spacedBottomForMiui.spacedBottom {
  height: var(--inAppSpacedBottomForMiui);
}

/**
 * Helper for hidden blocks
 * TODO: Unneeded thing for css-modules
 *
 * DON'T USE THESE CLASSES. It's the backward compatibility with the all code base
 */
:global(.hidden),
:global(.is-hidden) {
  /* Must be stronger than other 'display' properties */
  display: none !important; /* stylelint-disable-line */
}

/**
 * Mobile specific styles
 */

/* Used for media gallery */
:global(.pinch-zoom-container) {
  width: 100%;
  height: 100%;
}

/* unify display opacity on all mobile browsers */
:global(input:disabled) {
  opacity: 1;
  color: var(--themeTextColorMuted);
  -webkit-text-fill-color: var(--themeTextColorMuted);
}

@media screen and (min-width: 352px) and (max-width: 384px) {
  html.zoom {
    zoom: 1.1;
  }
}

@media screen and (min-width: 384px) and (max-width: 767px) {
  html.zoom {
    zoom: 1.2;
  }
}
