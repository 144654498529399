.popup {
  overflow: hidden;
}

.progressContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: var(--themeTextColor);
}

.progress {
  width: 100px;
  height: 100px;
}

.progressMirror {
  transform: scaleX(-1);
}

.expires.progressContainer {
  animation: progressColor 3s forwards;
}

.expires .progress {
  animation: progressScale 300ms 1500ms infinite alternate ease-in-out;
}

.timer {
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  text-align: center;
  font: var(--themeH2);
}

.confetti {
  stroke: none;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes progressColor {
  from {
    color: var(--themeTextColor);
    stroke: var(--skinPrimary);
  }
  to {
    color: var(--skinDanger);
    stroke: var(--skinDanger);
  }
}

@keyframes progressScale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
