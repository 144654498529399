.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.space {
  padding: 7px 0 7px 10px;
}

.header {
  border-radius: inherit;
}

.headerColor {
  background: var(--skinHeader);
}

.highlightedColor {
  background: var(--skinPrimaryLite);
}

.action {
  margin-right: 10px;
  flex-shrink: 0;
}

.info {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.priceHolder {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
}
