.container {
  z-index: 1;
}

.text {
  text-align: center;
}

.action {
  min-width: 200px;
  margin-bottom: 40px; /* to align with navigation arrows */
}
