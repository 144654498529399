.banner {
  text-align: center;
}

.wrap {
  padding-top: 20px;
}

.text {
  max-width: 260px;
  margin: auto;
}

.button {
  min-width: 160px;
  margin: auto;
}

.photosWrap {
  display: flex;
  justify-content: center;
}

.photos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -4px;
}

.photoWrap {
  width: 50%;
}

.photo {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
  flex-grow: 1;
  margin: 2px;
  border-radius: var(--themeRadius);
  color: var(--themeCard);
}

@media screen and (min-width: 768px) {
  .photos {
    /* 4px photo margin */
    max-width: 284px;
    max-height: 284px;
  }
}
