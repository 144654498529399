.massBlocked {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Elements */
.recaptcha {
  display: flex;
  justify-content: center;
  margin: 0 auto 4px;
}

.error {
  text-align: center;
}

.blockedText {
  text-align: center;
  margin-bottom: 40px;
}

.withReason {
  padding: 40px;
}

.withoutReason .recaptcha {
  margin: 0 auto;
}

.withoutReason .blockedText {
  display: none;
}

@media (max-width: 360px) {
  .massBlocked {
    transform: scale(0.86);
  }
}
