.footer {
  padding: 40px 20px 20px;
  text-align: center;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);
}

/* Bottom fixed actions on user page overlaps footer text and link */
.withSpacedAction {
  padding-bottom: 100px; /* 60px actions height + 20px spacings on top and bottom */
}

/* Reset styles for p, because we received wrapped text from backend */
.footer p {
  margin: 0;
  padding: 0;
}
