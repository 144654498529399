.content {
  overflow-x: hidden;
}

.users {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user {
  /** Animation delay to wait popup opening animation */
  --extraProfilesViewPopupAnimationDelay: 800ms;

  border-radius: var(--themeRadius);
  background: var(--themeCardLight);
}

.user:nth-child(2) {
  z-index: 1;
  width: 200px;
  height: 200px;
  position: relative;
}

.user:first-child,
.user:last-child {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 20px;
}

.user:first-child {
  right: 50%;
}

.user:last-child {
  left: 50%;
}

.usersWithTimer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
}

.timerWrapp {
  display: inline-block;
}

.overlay {
  border-radius: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: fadein 0.5s reverse both;
}

.user:nth-child(1) .overlay {
  animation-delay: calc(var(--extraProfilesViewPopupAnimationDelay) + 500ms);
}

.user:nth-child(2) .overlay {
  animation-delay: calc(var(--extraProfilesViewPopupAnimationDelay) + 1s);
}

.user:nth-child(3) .overlay {
  animation-delay: var(--extraProfilesViewPopupAnimationDelay);
}

.overlayExpired {
  composes: overlay;
  animation-direction: normal;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
