.wrap {
  width: 120px;
  margin: auto;
  position: relative;
}

.icon {
  position: absolute;
  border-radius: var(--themeRadiusLarge);
  bottom: 0;
  right: 0;
}

.actionWrapper {
  max-width: 100%;
}

.preview {
  clip-path: inset(0 0 round var(--themeRadiusLarge));
  overflow: hidden;
}
