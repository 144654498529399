.wrapper {
  height: inherit;
}

.content {
  padding: 40px 40px 0;
}

.header {
  height: 60px; /* Fixed size. Can't be bigger or smaller. As on design */
  background-color: var(--themeCard); /* Since we can see content below heading */
  border-bottom: 1px solid var(--themeDivider);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.withHeader {
  height: 100%;
}

.title {
  margin: 40px 60px 0;
}

.titleCenter {
  text-align: center;
}

.titleLeft {
  text-align: left;
  margin-left: 40px;
}

.withTitle {
  padding-top: 20px;
}

.spacedAbove {
  padding-top: 60px;
}

.withoutActions {
  padding-bottom: 40px;
}

.withoutActions.unspacedBelow {
  padding-bottom: 20px;
}

.actions {
  padding: 40px;
  z-index: 200; /* To be greater than loader's icon. See .active .icon::after in Loader.css */
}

.fixedActions.actionWithTopMargin {
  margin-top: 40px;
}

.fixedActions {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 20px;
  border-top-left-radius: var(--themeRadiusBar) !important; /* stylelint-disable-line */
  border-top-right-radius: var(--themeRadiusBar) !important; /* stylelint-disable-line */
}

.fixedActionsAnimationFallback {
  position: fixed;
}

.animationFallback {
  position: static;
}

.footer {
  /* We should hide bottom shadow of sticked button */
  position: relative;
  z-index: 11;
  background-color: var(--themeCard);

  /**
   * In case if we use "Bar" component on certain themes where it is placed with "margins" we should avoid
   * shadow overlaping in such way
   */
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

/**
 * @see Breakpoint.js
 */
.phone .content {
  padding-left: 20px;
  padding-right: 20px;
}

.phone .title {
  margin-top: 20px;
}

.phone .titleLeft {
  margin-left: 20px;
}

.phone .actions {
  padding: 20px;
}

.phone .fixedActions {
  padding: 10px;
}

.phone .fixedActions.actionWithTopMargin {
  margin-top: 20px;
}

.phone .withTitle {
  padding-top: 20px;
}

.phone .withoutActions {
  padding-bottom: 20px;
}
