.play {
  font-size: 60px;
  z-index: 1;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.action {
  margin: auto;
}
