.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background: inherit;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemWrapper {
  height: 20px; /* icons must have the sav height */
}

.buttonWrapper {
  display: inline-block;
}

/*
 * On pendingDelete we need still render block but hide it
 * to avoid bugs on IOS after deleting photo and rerender controls on next photo
 * bug description: after photo deletion controls on next photo are out of visible area (on ios 10, iphone 7)
*/
.hidden {
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 768px) {
  .container {
    justify-content: center;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    width: calc(var(--appWidth) / 2);
  }

  .button {
    flex-grow: 0;
    min-height: 45px;
  }
}
