.wrap {
  text-align: center;
}

.avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
}

.iconWrap {
  position: relative;
  background: #2aabee;
  border: 2px solid var(--themeCard);
  margin-top: -20px;
  z-index: 2;
}

.icon {
  font-size: 20px;
  fill: var(--themeTag);
  color: var(--themeTextColorInverse);
}

.photo {
  position: relative;
  border-radius: var(--themeRadiusLarge);
  background: var(--themeCard);
}

.photo:nth-child(2) {
  z-index: 2;
  width: 120px;
  height: 120px;
  margin-left: -20px;
  margin-right: -20px;
  box-sizing: content-box;
  border: 2px solid var(--themeCard);
}

.photo:first-child,
.photo:last-child {
  width: 80px;
  height: 80px;
}
