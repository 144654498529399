.preview {
  width: 60px;
  height: 60px;
  border-radius: var(--themeRadiusLarge);
  overflow: hidden;
  margin: 4px;
}

.errors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
}
