.item {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin: 0 0 2px 2px;
  text-align: center;
}

.item::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.twoColumns {
  margin: 0 0 10px 10px;
  width: calc(100% / 2 - 5px);
}

.twoColumns:nth-child(2n + 1) {
  margin-left: 0;
}

.threeColumns {
  width: calc(100% / 3 - 1.5px);
}

.threeColumns:nth-child(3n + 1) {
  margin-left: 0;
}

.fourColumns {
  width: calc(100% / 4 - 1.5px);
}

.fourColumns:nth-child(4n + 1) {
  margin-left: 0;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  cursor: pointer;
}

.dark {
  background: #000;
}
