.item {
  margin-top: 2px;
}

.item:first-child {
  margin-top: 0;
}

.inner {
  vertical-align: top;
}
