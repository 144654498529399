.logo {
  width: inherit;
  height: inherit;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.normal {
  max-width: 160px;
  max-height: 40px;
}

.medium {
  max-width: 240px;
  max-height: 60px;
}

.large {
  max-width: 320px;
  max-height: 80px;
}

.image {
  display: block;
  max-height: inherit;
  max-width: inherit;
}

.normal .image {
  height: 40px;
}

.medium .image {
  height: 60px;
}

.large .image {
  height: 80px;
}

.fullWidth {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.center {
  justify-content: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}
