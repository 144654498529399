.container {
  /** Animation delay to wait popup opening animation */
  --extraBuyThreeDayPopupAnimationDelay: 1s;

  max-width: 260px;
  margin: 0 auto;
}

.previousPackage {
  animation: previousPackage 600ms var(--extraBuyThreeDayPopupAnimationDelay) both;
}

.package {
  animation: package 600ms var(--extraBuyThreeDayPopupAnimationDelay) both;
}

.packageHeaderWrapper {
  border-radius: var(--themeRadius) var(--themeRadius) 0 0;
}

.subHeader {
  animation: fadein 600ms var(--extraBuyThreeDayPopupAnimationDelay) both;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes package {
  from {
    transform: translateY(-96px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes previousPackage {
  from {
    transform: translateY(-36px);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 0.5;
  }
}
