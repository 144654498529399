.container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;
  line-height: 0;
}

.container::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.willChange {
  will-change: transform; /* Corrects radius when blurred */
}

.loading {
  background: var(--themeCardLight);
}

.image {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

/* react like gallery need 100% height in all wrappers */
.fullHeight {
  height: 100%;
}
