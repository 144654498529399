.payButton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.levelDescription {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin-bottom: 20px;
}

.withoutBottom {
  margin-bottom: 0;
}

.preventEvents {
  pointer-events: none;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
