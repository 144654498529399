/*
  These are abstract animations, widely used across the app.
  E.g. for Microfeatures animations.
  @see src/packages/dating/camsStreaming/containers/BuzzButton.js
  @see src/packages/dating/freeFeaturePopup/configs/boostUpsellConfig.js
*/

.shiver {
  animation: shiver 3.08s infinite ease-in-out;
}

.boost {
  animation: boost 2.7s infinite ease-out;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes shiver {
  from,
  13%,
  19.5%,
  32.5%,
  to {
    transform: rotate(0);
  }
  1.3%,
  3.9%,
  6.5%,
  9.1%,
  11.7%,
  20.8%,
  23.4%,
  26%,
  28.6%,
  31.2% {
    transform: rotate(-4deg);
  }
  2.6%,
  5.2%,
  7.8%,
  10.4%,
  22.1%,
  24.7%,
  27.3%,
  29.9% {
    transform: rotate(4deg);
  }
}

@keyframes boost {
  from,
  82%,
  to {
    transform: translate(0);
  }
  86% {
    transform: translate(-67px, 87px);
  }
  90% {
    transform: translate(-67px, -77px);
  }
  92% {
    transform: translate(78px, -77px);
  }
}

@keyframes bounce {
  from,
  37%,
  57%,
  to {
    transform: translateY(0);
  }
  15% {
    transform: translateY(19%);
  }
  18%,
  24% {
    transform: translateY(-25%) rotate(-4deg);
  }
  21%,
  27% {
    transform: translateY(-25%) rotate(4deg);
  }
  32% {
    transform: translateY(-25%);
  }
  47% {
    transform: translateY(-12%);
  }
}
