.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeIconInverse);
  flex-shrink: 0;
  overflow: hidden;
  border-radius: var(--themeRadius);
}

.round {
  border-radius: 50%;
}

.none {
  width: 100%;
  height: 100%;
}

.small {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.normal {
  width: 60px;
  height: 60px;
  font-size: 24px;
}

.large {
  width: 80px;
  height: 80px;
  font-size: 36px;
}

.inline {
  display: inline-flex;
}

.withBorder {
  border: 2px solid var(--themeCard);
}

/* Color modifications */
.primary {
  background: var(--skinPrimary);
}

.danger {
  background: var(--skinDanger);
}

.success {
  background: var(--skinSuccess);
}

.accent {
  background: var(--skinAccent);
}

.warning {
  background: var(--skinWarning);
}

.headerColor {
  background: var(--skinHeader);
}
