.proxy {
  /**
   * do not use height!!
   * because it does not work in safari on tablets and mobile phones
   */
  min-height: 100%;
  width: 100%;
  flex-grow: 1;
  border-radius: inherit;
}

.wrap {
  composes: proxy;
  display: flex;
}
