.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.icon,
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  /* No need set negative margin to positioned icon, it will shift absolutely positioned icon from center */
  margin: 0 !important; /* stylelint-disable-line */
}

.text {
  width: 100%;
  justify-content: center;
}
