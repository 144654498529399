.action {
  text-align: center;
}

.shiftedAction {
  composes: action;

  /**
   * Since input and button should be on one line
   * but input have label on top
   * @see InputWrapper.css ("animated" rule)
   */
  padding-top: 20px;
}

.item {
  width: 50%;
}
