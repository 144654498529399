.video {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.itemUpload {
  cursor: pointer;
  justify-content: center;
  border: none;
  padding-top: 0;
  width: 100%;
  background: transparent;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.photo {
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
}

.play {
  font-size: 60px;
  z-index: 1;
}

.smallPlay {
  composes: play;
  font-size: 48px;
}

.add {
  font-size: 36px;
  text-align: center;
}

.addIcon {
  color: var(--themeIcon);
  font-size: 36px; /* Make icon big */
  margin: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addText {
  margin-top: 4px;
}

.menu {
  position: absolute;
  top: 10px;
  right: 10px;
  white-space: nowrap;
  z-index: 500;
}

.progress {
  height: 100%;
  width: 100%;
}

.error {
  position: relative;
  z-index: 1;
}

.errorIcon {
  color: #fff;
  opacity: 0.9;
  font-size: 60px;
}

.errorText {
  color: #fff;
  font: var(--themeTextSmall);
  padding: 10px;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .addIcon {
    font-size: 24px;
    margin: -2px;
  }

  .bigIcon .addIcon {
    font-size: 36px; /* Make icon big */
    margin: -3px;
  }
}
