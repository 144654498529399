.container {
  position: relative;
}

.progress {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.indicator {
  position: absolute;
  top: 5px;
  right: 5px;
  animation: recording 3s linear infinite;
}

/* Animation */
@keyframes recording {
  80% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
}
