.container {
  width: 100%;
  background: #000;
  display: flex;
  padding: 10px 0;
}

@media (min-width: 768px) {
  .container {
    justify-content: center;
  }

  .button {
    min-height: 45px;
  }
}
