.container {
  width: 320px;
  height: 240px;
  margin: 0 auto;
  background: #000;
  color: var(--themeTextColorInverse);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
