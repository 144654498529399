.content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
}

.contentNormal {
  padding: 10px;
  composes: content;
  flex-direction: column;
}

.fullHeight {
  height: 100%;
}

.header {
  color: var(--skinDanger);
}

.headerSmall {
  composes: header;
  font: var(--themeTextSmall);
  padding-right: 10px;
}

.headerNormal {
  composes: header;
  font: var(--themeH4);
}

.text {
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted));
}

