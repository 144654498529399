.icon {
  position: absolute;
  font-size: 34px;
  color: var(--skinAccent);
}

.icon:nth-child(1) {
  animation: sexAlarmSuccess1 6.16s ease-in 0s both infinite;
  left: 6%;
}

.icon:nth-child(2) {
  animation: sexAlarmSuccess2 6.16s ease-in 0.8s both infinite;
  left: 32%;
}

.icon:nth-child(3) {
  animation: sexAlarmSuccess3 6.16s ease-in 1.5s both infinite;
  left: 58%;
}

.icon:nth-child(4) {
  animation: sexAlarmSuccess4 6.16s ease-in 0.95s both infinite;
  left: 86%;
}

.icon:nth-child(5) {
  animation: sexAlarmSuccess5 6.16s ease-in 0.65s both infinite;
  left: 18%;
}

.icon:nth-child(6) {
  animation: sexAlarmSuccess6 6.16s ease-in 1.3s both infinite;
  left: 43%;
}

.icon:nth-child(7) {
  animation: sexAlarmSuccess7 6.16s ease-in 0.4s both infinite;
  left: 74%;
}

@keyframes sexAlarmSuccess1 {
  from,
  to {
    top: -15%;
    opacity: 0.5;
  }
  16% {
    top: 104%;
  }
  17%,
  99% {
    opacity: 0;
  }
}

@keyframes sexAlarmSuccess2 {
  from,
  to {
    top: -13%;
    opacity: 0.5;
  }
  16% {
    top: 108%;
  }
  17%,
  99% {
    opacity: 0;
  }
}

@keyframes sexAlarmSuccess3 {
  from,
  to {
    top: -15%;
    opacity: 0.5;
  }
  16% {
    top: 107%;
  }
  17%,
  99% {
    opacity: 0;
  }
}

@keyframes sexAlarmSuccess4 {
  from,
  to {
    top: -13%;
    opacity: 0.5;
  }
  16% {
    top: 105%;
  }
  17%,
  99% {
    opacity: 0;
  }
}

@keyframes sexAlarmSuccess5 {
  from,
  to {
    top: -27%;
    opacity: 0.5;
  }
  16% {
    top: 117%;
  }
  17%,
  99% {
    opacity: 0;
  }
}

@keyframes sexAlarmSuccess6 {
  from,
  to {
    top: -25%;
    opacity: 0.5;
  }
  16% {
    top: 119%;
  }
  17%,
  99% {
    opacity: 0;
  }
}

@keyframes sexAlarmSuccess7 {
  from,
  to {
    top: -27%;
    opacity: 0.5;
  }
  16% {
    top: 117%;
  }
  17%,
  99% {
    opacity: 0;
  }
}
