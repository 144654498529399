.icon {
  position: absolute;
  color: var(--skinHeader);
  opacity: 0;
}

.icon:nth-child(1) {
  animation: incognitoSuccess 11s 1.5s infinite;
  font-size: 22px;
  top: 46%;
  left: 11%;
}

.icon:nth-child(2) {
  animation: incognitoSuccess 11s 3s infinite;
  font-size: 29px;
  top: 24%;
  right: 20%;
}

.icon:nth-child(3) {
  animation: incognitoSuccess 11s 4.5s infinite;
  font-size: 35px;
  top: 29%;
  left: 20%;
}

.icon:nth-child(4) {
  animation: incognitoSuccess 11s 6s infinite;
  font-size: 35px;
  top: 41%;
  right: 10%;
}

.icon:nth-child(5) {
  animation: incognitoSuccess 11s 7.5s infinite;
  font-size: 24px;
  top: 5%;
  left: 45%;
}

@keyframes incognitoSuccess {
  from,
  25%,
  to {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  10%,
  15% {
    opacity: 0.5;
    animation-timing-function: ease-out;
  }
}
