body {
  /**
   * Because without such rule, until React is mounted
   * we can get flashing screen of different color.
   */
  background: var(--themePage);
}

.root {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin: 0;
  display: flex;
  min-width: 0;
  color: var(--themeTextColor);
  background: var(--themePage);
  font: var(--themeText);
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
}
