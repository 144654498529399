.container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.action {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 5;
}

.badge {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
}

.badgeOnTopRight {
  top: 0;
  right: 0;
  left: auto;
  bottom: auto;
}

.offsetBadge {
  bottom: -5px;
  right: -5px;
}

.status {
  position: absolute;
  z-index: 10;
}

.top {
  top: 4px;
  right: 4px;
}

.bottom {
  bottom: 14%;
  right: 14%;
  transform: translate(50%, 50%);
}

.counters {
  position: absolute;
  right: 4px;
  bottom: 4px;
  text-align: right;
}
