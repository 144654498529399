.image {
  border-radius: var(--themeRadiusLarge);
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 auto;
}

.text {
  word-wrap: break-word;
}
