.close {
  width: 180px;
  margin: auto;
}

.wrap {
  padding-bottom: 40px;
}

.disableCopy *:not(input) {
  -webkit-touch-callout: none; /* Safari */
  user-select: none; /* Webkit */
}

/* All these selectors come from PHP views, and we can't change them */
:global(.staticContent ul) {
  /**
   *  Somebody very smart put inline style in terms translation on server.
   *  For prevent this logic put important.
   */
  list-style: none !important; /* stylelint-disable-line */
  padding: 0 !important; /* stylelint-disable-line */
  margin: 0;
}

:global(.staticContent ul li::before) {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  color: var(--skinPrimary);
  font-size: 24px;
}

:global(.staticContent li) {
  padding-left: 15px;
  margin-bottom: 4px;
  position: relative;
}

:global(.staticContent .page-centerer) {
  color: var(--themeTextColorMuted);
}

:global(.staticContent b),
:global(.staticContent h1),
:global(.staticContent h2),
:global(.staticContent h3),
:global(.staticContent h4) {
  color: var(--themeTextColor);
  font-weight: bold;
}

:global(.staticContent h1),
:global(.staticContent h2),
:global(.staticContent h3),
:global(.staticContent h4) {
  margin-bottom: 20px;
}

:global(.staticContent .info-item) {
  margin-bottom: 20px;
}

:global(.staticContent .info-item:last-child) {
  margin: 0;
}

:global(.staticContent .info-item .title) {
  color: var(--skinPrimary);
  font: var(--themeH4);
}

:global(.staticContent h1) {
  margin-top: 0;
  font: var(--themeH3);
  font-weight: 700;
}

/* head with logo */
:global(.staticContent .head) {
  text-align: center;
  margin: 0 0 40px;
}

:global(.staticContent .logo) {
  max-width: 140px;
}

:global(.staticContent .payment-static-page_visa) {
  margin: 20px 0;
  width: 120px;
  height: 70px;
  background: url(../images/visa.png) no-repeat center top;
}

:global(.staticContent .payment-static-page_mastercard) {
  margin: 20px 0;
  width: 140px;
  height: 50px;
  background: url(../images/mastercard.png) no-repeat center top;
}

/* text content container */
:global(.staticContent .text-wrap) {
  margin: 40px 0;
}

:global(.staticContent .card) {
  box-shadow: none;
}

/* Hide any possible buttons */
:global(.staticContent .payment-static-page_actions),
:global(.staticContent .b-btn-close),
:global(.staticContent .stc-close) {
  display: none;
}

:global(.staticContent .b-page-additionalPackageConditions ul) {
  padding-left: 40px !important; /* stylelint-disable-line */
}

:global(.staticContent .b-page-dnsmpi h4) {
  margin-bottom: 4px;
}

:global(.staticContent .b-page-dnsmpi h4 + div),
:global(.staticContent .b-page-dnsmpi .list) {
  margin-bottom: 20px;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);
}

:global(.staticContent .b-page-dnsmpi br) {
  display: none;
}

:global(.staticContent table a) {
  display: inline-block;
  word-break: break-all;
  text-overflow: ellipsis; /* A crutch for fixing a bug on iOS 16 */
}

:global(.b-page-chatrules .terms-privacy_block h4) {
  margin-bottom: 0;
  font: var(--themeH3);
}

:global(.b-page-chatrules .terms-privacy_block b) {
  margin-bottom: 2px;
  font: var(--themeH4);
}

/** Styles for Contact us static page */
:global(.is-subpage-contact-us .b-page-contact-us__map) {
  margin: 0 auto;
  max-width: 720px;
  display: flex;
  background: var(--themeCard);
  box-shadow: var(--themeShadow1);
}

:global(.is-subpage-contact-us .title) {
  padding: 0 0 15px;
  font-size: 24px;
  border: none;
  color: var(--themeTextColor);
}

:global(.is-subpage-contact-us .subtitle) {
  font-size: 24px;
  font-weight: 400;
  color: var(--themeTextColorMuted);
}

:global(.is-subpage-contact-us .map-china) {
  order: 0;
  flex-shrink: 0;
  width: 340px;
  min-height: 400px;
  background: url(../images/map_small.png) center no-repeat;
  background-size: cover;
}

:global(.staticContent .b-page-contact-us__map .info) {
  order: 1;
  padding: 40px;
}

:global(.is-subpage-contact-us .b-text) {
  margin: 15px 0;
  font-size: 18px;
  color: var(--themeTextColorMuted);
}

:global(.is-subpage-contact-us .b-text-highlight) {
  color: var(--themeTextColor);
}

:global(.is-subpage-contact-us .static-footer) {
  padding-top: 20px;
  font-size: var(--themeTextSmall);
  text-align: center;
}

:global(.is-subpage-contact-us .footer_links-item) {
  margin: 0 10px;
}

:global(.is-subpage-contact-us .footer_links-item:first-child) {
  margin-left: 0;
}

:global(.is-subpage-contact-us .footer_links-item:last-child) {
  margin-right: 0;
}

/** Styles for About Company static page */
:global .is-subpage-about-company .staticContent {
  padding: 0;
}

:global .about-company {
  padding: 0 10px;
  font: var(--themeText);
}

:global .about-company_container {
  padding: 40px 0;
  border-bottom: 1px solid var(--themeDivider);
}

:global .about-company_container:first-child {
  padding-top: 0;
}

:global .about-company_container:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

:global .about-company_content {
  max-width: 940px;
  margin: 0 auto;
}

:global .about-company_title {
  font-size: 30px;
  font-weight: normal;
  color: var(--skinAccent);
  text-align: center;
}

:global .about-company_title-company {
  padding: 0 50px 30px 50px;
  font-size: 24px;
  color: var(--skinPrimary);
}

:global .about-company_text {
  padding: 30px;
  text-align: center;
  color: var(--themeTextColorMuted);
}

:global .about-company_wrap {
  display: flex;
  justify-content: space-between;
}

:global .about-company_container-first .about-company_text {
  padding: 30px 100px;
}

:global .about-company_container-first .about-company_item {
  width: 190px;
  padding-top: 190px;
  margin-right: 75px;
}

:global .about-company_container-first .about-company_item::before {
  width: 190px;
  height: 190px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

:global .about-company_container-first .about-company_item-first-first {
  margin-left: 0;
}

:global .about-company_container-first .about-company_item-first::after,
:global .about-company_container-first .about-company_item-second::after {
  content: '';
  position: absolute;
  width: 75px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

:global .about-company_container-first .about-company_item-first::after {
  top: 80px;
  right: -75px;
  background-image: url(../images/arrow.png);
}

:global .about-company_container-first .about-company_item-first::before {
  background-image: url(../images/group_1.png);
}

:global .about-company_container-first .about-company_item-second::before {
  background-image: url(../images/group_2.png);
}

:global .about-company_container-first .about-company_item-second::after {
  top: 90px;
  right: -80px;
  background-image: url(../images/arrow-2.png);
}

:global .about-company_container-first .about-company_item-third {
  margin-right: 0;
}

:global .about-company_container-first .about-company_item-third::before {
  background-image: url(../images/group_3.png);
}

:global .about-company_container-second .about-company_item {
  width: 170px;
  padding-top: 110px;
  margin-left: 10px;
}

:global .about-company_container-second .about-company_item::before {
  width: 170px;
  height: 100px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

:global .about-company_container-second .about-company_item-first {
  margin-left: 0;
}

:global .about-company_container-second .about-company_item-first::before {
  background-image: url(../images/group_4.png);
}

:global .about-company_container-second .about-company_item-second::before {
  background-image: url(../images/group_5.png);
}

:global .about-company_container-second .about-company_item-third::before {
  background-image: url(../images/group_6.png);
}

:global .about-company_container-second .about-company_item-fourth::before {
  background-image: url(../images/group_7.png);
}

:global .about-company_item {
  position: relative;
  text-align: center;
}

:global .about-company_item-title {
  color: var(--skinAccent);
  margin: 20px 0;
  font-weight: normal;
}

:global .about-company_item-text {
  color: var(--themeTextColorMuted);
}

:global .about-company_btn-text {
  font-size: var(--themeTextSmall);
  position: absolute;
  top: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  width: 100%;
  color: var(--themeCard);
}

:global .about-company_info-title {
  font-size: 30px;
  font-weight: bold;
  color: var(--skinPrimary);
}

:global .about-company_container-third .about-company_item {
  width: 220px;
  padding-top: 230px;
}

:global .about-company_container-third .about-company_item::before {
  width: 220px;
  height: 220px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

:global .about-company_container-third .about-company_item-first::before {
  background-image: url(../images/photo_1.png);
}

:global .about-company_container-third .about-company_item-second::before {
  background-image: url(../images/photo_2.png);
}

:global .about-company_container-third .about-company_item-third::before {
  background-image: url(../images/photo_3.png);
}

:global .footer_wrap {
  max-width: 940px;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
}

:global .main-footer_wrapp {
  font: var(--themeTextSmall);
  color: var(--themeTextColor);
}

:global .footer_links {
  margin-bottom: 10px;
}

:global .main-footer_link {
  margin: 0 4px;
}

@media screen and (min-width: 1024px) {
  :global .about-company {
    padding: 0;
  }

  :global .about-company_wrap {
    justify-content: space-around;
  }

  :global .about-company_container-first .about-company_text {
    padding: 30px 190px;
  }

  :global .about-company_container-first .about-company_item {
    width: 220px;
    padding-top: 220px;
  }

  :global .about-company_container-first .about-company_item::before {
    width: 220px;
    height: 220px;
  }

  :global .about-company_container-first .about-company_item-first::after,
  :global .about-company_container-first .about-company_item-second::after {
    width: 90px;
  }

  :global .about-company_container-first .about-company_item-first::after {
    top: 90px;
    right: -90px;
  }

  :global .about-company_container-first .about-company_item-second::after {
    top: 100px;
    right: -90px;
  }

  :global .about-company_container-second .about-company_item {
    width: 220px;
    padding-top: 150px;
  }

  :global .about-company_container-second .about-company_item::before {
    width: 220px;
    height: 130px;
  }

  :global .about-company_btn-text {
    top: 95px;
  }

  :global .about-company_container-third .about-company_item {
    width: 240px;
    padding-top: 260px;
  }

  :global .about-company_container-third .about-company_item::before {
    width: 240px;
    height: 260px;
  }
}

@media screen and (min-width: 1280px) {
  :global .about-company_container-first .about-company_item {
    width: 240px;
    padding-top: 240px;
  }

  :global .about-company_container-first .about-company_item::before {
    width: 240px;
    height: 240px;
  }

  :global .about-company_container-first .about-company_item-first::after {
    top: 110px;
    right: -85px;
  }

  :global .about-company_container-first .about-company_item-second::after {
    top: 120px;
  }

  :global .about-company_btn-text {
    top: 105px;
  }
}

/** Safety tips */
:global(.b-page-safety-tips h3) {
  font-weight: normal;
}

:global(.b-page-safety-tips p) {
  margin-bottom: 20px;
  font: var(--themeTextSmall);
}

:global(.b-page-safety-tips .title-with-icon) {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

:global(.b-page-safety-tips .title-with-icon h3) {
  margin-bottom: 0;
}

:global(.b-page-safety-tips .icon) {
  font-size: 24px;
  margin: -2px;
  color: var(--themeIcon);
}

:global(.b-page-safety-tips .icon-wrapper) {
  margin-right: 20px;
}

/** About us */
:global(.b-page-about-us .headMob) {
  margin-top: -40px;
  text-align: center;
}

:global(.b-page-about-us .head) {
  margin: -20px 0 0 0;
}

:global(.b-page-about-us p) {
  text-align: center;
  margin: 20px 0;
}

:global(.b-page-about-us .logo) {
  height: 40px;
  max-width: 160px;
}

:global(.b-page-about-us p:last-child) {
  margin-bottom: 0;
}
