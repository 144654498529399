.popup {
  text-align: center;
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  position: relative;
  z-index: 1;
  color: var(--themeIconMuted);
  animation: emergence 600ms ease-out 600ms normal forwards;
}

.action {
  min-width: 200px;
  margin: 0 auto;
}

.animationHolder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animationBubble,
.animationCircle {
  border-radius: 50%;
}

.animationBubble {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  width: 24px;
  height: 24px;
  background-color: var(--themeDivider);
  opacity: 0; /* So that it is not displayed before the start of the animation */
}

.animationCircle {
  width: 96px;
  height: 96px;
  animation: growCircle 300ms ease-in-out 800ms normal both;
}

.animationBubble:nth-child(1) {
  animation: bubble1 1.7s cubic-bezier(0.1, 1, 0.3, 1) 800ms normal forwards;
}

.animationBubble:nth-child(2) {
  animation: bubble2 1.7s cubic-bezier(0.1, 1, 0.3, 1) 800ms normal forwards;
}

.animationBubble:nth-child(3) {
  animation: bubble3 1.7s cubic-bezier(0.1, 1, 0.3, 1) 800ms normal forwards;
}

.animationBubble:nth-child(4) {
  animation: bubble4 1.7s cubic-bezier(0.1, 1, 0.3, 1) 800ms normal forwards;
}

.animationBubble:nth-child(5) {
  animation: bubble5 1.7s cubic-bezier(0.1, 1, 0.3, 1) 800ms normal forwards;
}

.animationBubble:nth-child(6) {
  animation: bubble6 1.7s cubic-bezier(0.1, 1, 0.3, 1) 800ms normal forwards;
}

@keyframes emergence {
  0%,
  100% {
    transform: scale(1);
  }

  15% {
    transform: scale(0);
  }

  20%,
  100% {
    color: var(--skinPrimary);
  }

  80% {
    transform: scale(1.14);
  }
}

@keyframes bubble1 {
  0% {
    opacity: 1;
    transform: translate(0, -25px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(0, -75px) scale(0);
  }
}

@keyframes bubble2 {
  0% {
    opacity: 1;
    transform: translate(23px, -13px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(85px, -42px) scale(0);
  }
}

@keyframes bubble3 {
  0% {
    opacity: 1;
    transform: translate(23px, 13px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(85px, 42px) scale(0);
  }
}

@keyframes bubble4 {
  0% {
    opacity: 1;
    transform: translate(0, 25px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(0, 75px) scale(0);
  }
}

@keyframes bubble5 {
  0% {
    opacity: 1;
    transform: translate(-23px, 13px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(-85px, 42px) scale(0);
  }
}

@keyframes bubble6 {
  0% {
    opacity: 1;
    transform: translate(-23px, -13px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(-85px, -42px) scale(0);
  }
}

@keyframes growCircle {
  0% {
    transform: scale(0);
    box-shadow: 0 0 0 10px var(--themeDivider);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 var(--themeDivider);
  }
}
