.wrapper {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
}

.holder {
  position: relative;
  width: inherit;
  display: flex;
  flex-grow: 1;
  min-height: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.holder:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.actions {
  flex-shrink: 0;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
