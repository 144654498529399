.unit {
  width: 100%;
  text-align: center;
}

.icon {
  margin-bottom: 10px;
}

.row {
  display: flex;
  align-items: center;
}

.label {
  min-width: 150px;
  margin-right: 10px;
}

.row .label {
  /* fix for loveis theme */
  flex-basis: initial;
}

.spacing {
  margin-top: 40px;
}

.footer {
  background: var(--themePage);
}

.block {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  padding-right: 20px;
}

.contacts {
  line-height: 20px;
}

.data {
  margin-right: 40px;
}

.signature {
  min-width: 100px;
}

@media screen and (max-width: 767px) {
  .row {
    align-items: baseline;
    word-break: break-word;
  }

  .label {
    margin: 0;
    padding-right: 10px;
    min-width: 50%;
    width: 50%;
  }

  .spacing {
    margin-top: 20px;
  }

  .block {
    flex-direction: column;
    justify-content: initial;
  }

  .wrapper {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .data {
    margin: 0;
  }

  .signature {
    order: -1;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 40px;

    /* Fix removal of space after text */
    white-space: pre;
  }
}
