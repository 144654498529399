.animation {
  animation: incognito 3s infinite;
}

@keyframes incognito {
  from,
  40%,
  to {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  10%,
  30% {
    transform: translateY(100px);
    animation-timing-function: cubic-bezier(0, 1.4, 0, 1);
  }
}
