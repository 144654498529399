.benefit {
  flex: 1;
  align-self: baseline;
}

.icon {
  margin: 0 auto;
}

.text {
  max-width: 65px;
  margin: 0 auto;
}

.content {
  max-width: 300px;
  margin: 20px auto 0;
}
